<template>
  <div class="px-main --dark">
    <h1 class="page-title-main py-4" v-if="dataRoom">
      {{ breadcrumbs }}
    </h1>

    <v-col cols="12" lg="8" class="pa-0">
      <div class="pa-5 mt-5 elevation-3 bg--white">
        <div class="t-item mt-0">
          <label>{{ $t("facility.settings.roomID") }}</label>
          {{ roomId }}
        </div>

        <v-form ref="form">
          <div class="t-item">
            <label>{{ $t("facility.settings.roomNameManagement") }}</label>
            <v-text-field v-if="dataRoom"
              v-model="dataRoom.name"
              :rules="[() => !!dataRoom.name || $t('facility.rentalList.rules.required'),
                      () => dataRoom.name.length < maxInt ||$t('rules.isInvalid')]"
              dense
              outlined
              class="pa-0 medium-input"
              :disabled="!checkPerUser"
            ></v-text-field>
          </div>

          <div class="t-item">
            <label>{{
              $t("facility.settings.managementScreenDisplayOrder")
            }}</label>
            <v-select v-if="dataRoom"
              v-model="dataRoom.order"
              @change="changeOrder($event)"
              :rules="[() => !!dataRoom.order || $t('facility.rentalList.rules.required')]"
              :items="listOrderScreen"
              outlined
              dense
              class="medium-input"
              :disabled="!checkPerUser"
            ></v-select>
          </div>

          <v-divider class="mt-10"></v-divider>

          <div class="t-item">
            <label>KeyvoxID</label>
            <v-text-field
              v-model="keyvoxId"
              hide-details
              dense
              outlined
              class="pa-0 medium-input"
              :disabled="!checkPerUser"
            ></v-text-field>
          </div>
        </v-form>

        <v-divider class="mt-10"></v-divider>

        <div class="t-item">
          <label class="align-self-start">{{
            $t("facility.settings.deleteThisRoom")
          }}</label>
          <div>
            {{ $t("facility.settings.deleteRoomNotice") }}
            <br />
            <v-btn :disabled="!checkPerUser" outlined class="btn-delete btn--pink-ounlined mt-1" @click="checkPerUser ? handleDelete() : ''">{{
              $t("buttons.delete")
            }}</v-btn>
          </div>
        </div>
      </div>
      <div class="text-right mt-8">
        <v-btn color="#004F8B" :disabled="!checkPerUser" class="mr-3" @click="checkPerUser ? handleSubmitHold() : ''">
          <span style="font-size: 16px; color: #FFFFFF">{{
          $t("buttons.saveAndUpdate")
        }}</span>
        </v-btn>
        <v-btn color="#004F8B" :disabled="!checkPerUser" @click="checkPerUser ? handleSubmit(): ''">
          <span style="font-size: 16px; color: #FFFFFF">{{ $t("buttons.saveAndClose") }}</span>
        </v-btn>
      </div>
    </v-col>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import { ROOM } from '@/api/graphql/facility-room'
import gql from 'graphql-tag'
export default {
  name: 'RoomDetail',
  data () {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      roomId: this.$route.query.id,
      listOrderScreen: [],
      maxInt: 256,
      keyvoxId: 2,
      dataRoom: null
    }
  },
  watch: {
    dataRoom () {
      document.title = this.breadcrumbs
    }
  },
  mounted () {
    this.getListOrder()
    this.getRoom()
  },
  methods: {
    ...mapMutations({ setFacilityRoom: 'setFacilityRoom' }),
    ...mapActions({
      actionUpdate: 'updateRoom',
      actionUpdateHold: 'updateRoomHold',
      actionDelete: 'deleteRoom'
    }),

    async getRoom () {
      await this.$apollo
        .query({
          query: gql`${ROOM}`,
          variables: {
            id: parseInt(this.$route.query.id)
          }
        })
        .then(data => {
          this.dataRoom = data.data.room
        })
        .catch(error => {
          console.error(error)
        })
    },

    changeOrder (event) {
      const facilityRoom = {
        order: this.dataRoom.order,
        name: this.dataRoom.name
      }
      this.setFacilityRoom({ facilityRoom: facilityRoom })
    },

    getListOrder () {
      for (let i = 1; i < 101; i++) {
        this.listOrderScreen.push(i)
      }
    },
    updateDataRoom () {
      const facilityRoom = {
        name: this.dataRoom.name,
        order: this.dataRoom.order
      }
      this.setFacilityRoom({ facilityRoom: facilityRoom })
      this.actionUpdate()
      this.$refs.form.resetValidation()
    },
    updateDataRoomHold () {
      const facilityRoom = {
        name: this.dataRoom.name,
        order: this.dataRoom.order
      }
      this.setFacilityRoom({ facilityRoom: facilityRoom })
      this.actionUpdateHold()
      this.$refs.form.resetValidation()
    },
    handleSubmit () {
      if (this.$refs.form.validate()) {
        this.updateDataRoom()
      }
    },
    handleSubmitHold () {
      if (this.$refs.form.validate()) {
        this.updateDataRoomHold()
      }
    },
    async handleDelete () {
      await this.actionDelete()
    }
  },
  computed: {
    ...mapGetters({ facilityRoom: 'facilityRoom' }),
    breadcrumbs () {
      if (this.dataRoom) {
        return this.dataRoom.roomType.facility.name + '/' + this.dataRoom.roomType.name + '/' + this.dataRoom.name
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.t-item {
  align-items: baseline
}
label{
  color: #666666;
  font-size: 16px;
}
::v-deep {
  input, .v-select__selection--comma, .t-item {
    color: #666666 !important;
    font-size: 16px  !important;
  }
  .btn-delete {
    span {
      font-size: 17px;
      color:#DB3069 !important
    }
  }
}
</style>
